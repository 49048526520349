export var PersonalizationFeatureFlags = {
    FCSNameSpace: "CXP.CXPCore",
    FCSNameSpaceCXPOrchestrationUI: "CXP.CXPOrchestrationUI",
    BusinessUnitScoping: "6508310d-8dfd-4812-b476-74398ca18ee7",
    DateTimeFormatting: "b7c01a44-bb50-48aa-ada0-1daee91bbde4",
    ListFilter: "061c1a79-ac49-4e69-8921-5241517caf1a",
    OneToManyForTokens: "5385c310-f565-40e5-bfc6-82aaf313dbb6",
    AdvancedList: "msdynmkt_20230614_IfUnderEach",
    IfUnderEachConditions: "msdynmkt_20230614_IfUnderEachConditions",
    OneToManyForConditions: "msdynmkt_20230912_OneToManyInConditions",
    VirtualTables: "msdynmkt_20230927_VirtualTables",
    PicklistsForTokens: "msdynmkt_20231114_PicklistsForTokens",
    GlobalCopilotDisabled: "219a6649-18a7-426f-bc13-22f5260b0ebc",
    FCSDynamicTextAi: "Enable_PersonalizationDynamicTextAi",
    FCSPicklistsForTokens: "Enable_PersonalizationPicklistsForTokens",
    FCSLiveEditOnEmail: "Enable_PersonalizationLiveEditOnEmail",
    FCSDoubleOptInEmail: "Enable_PersonalizationDoubleOptInEmail",
    FCSJourneyLinkHandlingV2: "Enable_JourneyLinkHandlingV2",
    FCSAllowOBMSegmentsInPersonalization: "Enable_AllowOBMSegmentsInPersonalization"
};
