var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AsyncDistributedExpirationCacheWithRefresh, indexDbStore, localStorageStore } from "@cxp-shared/caching";
import { isBusinessUnitScopingEnabled, resolveBusinessUnit } from "@cxp-shared/personalization-core";
import { Service } from "@cxp-shared/platform/Service";
import { defaultDatasetConfiguration } from "./DataSources/MetadataStoreDataverseDataSourceUtils";
import { MetadataStoreServiceName } from "./IMetadataStoreService";
import { brandLogicalName } from "./MetadataConstants";
export var CachedMetadataStoreServicePrefix = "CachedMetadataStoreService";
export var getMetadataItemsForPrefix = "getMetadataItemsFor";
export var getDataverseTableMetadataItemsPrefix = "getDataverseTableMetadataItems";
export var getAllCjoEventsMetadataItemsPrefix = "getAllCjoEventsMetadataItems";
export var getAllLegalMetadataItemsPrefix = "getAllLegalMetadataItems";
export var searchMetadataItemsContainingPrefix = "searchMetadataItemsContaining";
export var getAllCjoEventsNamesPrefix = "getAllCjoEventsNames";
export var getCjoEventsByNamePrefix = "getCjoEventsByName";
export var getSpecificSegmentsPrefix = "getSpecificSegments";
export var getDatasetConfiguration = "getDatasetConfiguration";
export var getEntityMetadataFromCRM = "getEntityMetadataFromCRM";
export var getAllMetadataEntities = "getAllMetadataEntities";
export var getDateFormatsPrefix = "getDateFormatsWithGroups";
export var DefaultMetadataCacheExpirationTime = 1000 * 60 * 60 * 6; // 6 Hours
export var DefaultMetadataCacheRefreshTimeout = 1000 * 60 * 5;
var CachedMetadataStoreService = /** @class */ (function (_super) {
    __extends(CachedMetadataStoreService, _super);
    function CachedMetadataStoreService(metadataStoreService, cacheConfig, cache) {
        var _this = _super.call(this) || this;
        _this.metadataStoreService = metadataStoreService;
        _this.getDataverseTableMetadataItems = function (sourceLogicalNames, useLazyRelatedEntitiesLoading, allowStaleData) {
            if (useLazyRelatedEntitiesLoading === void 0) { useLazyRelatedEntitiesLoading = false; }
            if (allowStaleData === void 0) { allowStaleData = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var sourceLogicalNamesArray, result;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            sourceLogicalNamesArray = Array.isArray(sourceLogicalNames) ? sourceLogicalNames : [sourceLogicalNames];
                            return [4 /*yield*/, this.cache.getMultiple("".concat(CachedMetadataStoreServicePrefix, "-").concat(getDataverseTableMetadataItemsPrefix), sourceLogicalNamesArray, function (keys) { return __awaiter(_this, void 0, void 0, function () {
                                    var data, groupedData;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.metadataStoreService.getDataverseTableMetadataItems(keys)];
                                            case 1:
                                                data = (_a.sent()).value;
                                                groupedData = keys.map(function (key) { return data.filter(function (d) { return d.sourceLogicalName === key; }); });
                                                return [2 /*return*/, groupedData];
                                        }
                                    });
                                }); }, allowStaleData)];
                        case 1:
                            result = _a.sent();
                            return [2 /*return*/, {
                                    value: result.flatMap(function (item) { return item; })
                                }];
                    }
                });
            });
        };
        _this.getAllCjoEventsMetadataItems = function (sourceLogicalNames, allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var fullEventMetadata;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.getCjoEventsByName(sourceLogicalNames, allowStaleData)];
                        case 1:
                            fullEventMetadata = _a.sent();
                            return [2 /*return*/, fullEventMetadata];
                    }
                });
            });
        };
        _this.getAllLegalMetadataItems = function (allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            var key = "".concat(CachedMetadataStoreServicePrefix, "-").concat(getAllLegalMetadataItemsPrefix);
            return _this.cache.get(key, function () { return _this.metadataStoreService.getAllLegalMetadataItems(); }, allowStaleData);
        };
        _this.searchMetadataItemsContaining = function (tokenInDisplayName, allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            var key = "".concat(CachedMetadataStoreServicePrefix, "-").concat(searchMetadataItemsContainingPrefix, "_").concat(tokenInDisplayName);
            return _this.cache.get(key, function () { return _this.metadataStoreService.searchMetadataItemsContaining(tokenInDisplayName); }, allowStaleData);
        };
        _this.cache = cache;
        if (!_this.cache) {
            indexDbStore.init();
            _this.cache = new AsyncDistributedExpirationCacheWithRefresh(indexDbStore, function () { return Date.now(); }, (cacheConfig === null || cacheConfig === void 0 ? void 0 : cacheConfig.expirationTimeMs) || DefaultMetadataCacheExpirationTime, (cacheConfig === null || cacheConfig === void 0 ? void 0 : cacheConfig.refreshTimeout) || DefaultMetadataCacheRefreshTimeout);
        }
        localStorageStore.init();
        return _this;
    }
    CachedMetadataStoreService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        if (!this.metadataStoreService) {
            this.metadataStoreService = appContext.getService(MetadataStoreServiceName);
        }
        else {
            this.metadataStoreService.serviceStart(appContext);
        }
    };
    CachedMetadataStoreService.prototype.getSpecificSegments = function (ids, allowStaleData) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var idsArray, result;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        idsArray = Array.isArray(ids) ? ids : [ids];
                        return [4 /*yield*/, this.cache.getMultiple("".concat(CachedMetadataStoreServicePrefix, "-").concat(getSpecificSegmentsPrefix), idsArray, function (keys) { return __awaiter(_this, void 0, void 0, function () {
                                var data, groupedData;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.metadataStoreService.getSpecificSegments(keys)];
                                        case 1:
                                            data = _a.sent();
                                            groupedData = keys.map(function (key) { return data.find(function (d) { return d.id === key; }); });
                                            return [2 /*return*/, groupedData];
                                    }
                                });
                            }); }, allowStaleData)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.filter(function (s) { return s; })];
                }
            });
        });
    };
    CachedMetadataStoreService.prototype.getDatasetConfiguration = function (allowStaleData) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var staticEntitiesRaw, logicalNames, datasetConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, localStorageStore.getItem(CachedMetadataStoreService.staticBindingEntitiesKey)];
                    case 1:
                        staticEntitiesRaw = _a.sent();
                        logicalNames = staticEntitiesRaw ? JSON.parse(staticEntitiesRaw) : defaultDatasetConfiguration;
                        return [4 /*yield*/, this.getDisplayNames(logicalNames, allowStaleData)];
                    case 2:
                        datasetConfig = _a.sent();
                        return [2 /*return*/, Object.keys(datasetConfig).map(function (logicalName) {
                                return {
                                    sourceDisplayName: datasetConfig[logicalName],
                                    sourceLogicalName: logicalName
                                };
                            })];
                }
            });
        });
    };
    CachedMetadataStoreService.prototype.getAllMetadataEntities = function (allowStaleData) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var key;
            var _this = this;
            return __generator(this, function (_a) {
                key = "".concat(CachedMetadataStoreServicePrefix, "-").concat(getAllMetadataEntities);
                return [2 /*return*/, this.cache.get(key, function () { return _this.metadataStoreService.getAllMetadataEntities(); }, allowStaleData)];
            });
        });
    };
    CachedMetadataStoreService.prototype.getDisplayNames = function (sourceLogicalNames, allowStaleData) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var result, retValue;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getEntityMetadataFromCRM(sourceLogicalNames, allowStaleData = false)];
                    case 1:
                        result = _a.sent();
                        retValue = Object.values(result).reduce(function (a, e) {
                            var _a;
                            return (__assign(__assign({}, a), (_a = {}, _a[e.LogicalName] = e.DisplayName, _a)));
                        }, {});
                        return [2 /*return*/, retValue];
                }
            });
        });
    };
    CachedMetadataStoreService.prototype.getEntityMetadataFromCRM = function (sourceLogicalNames, allowStaleData) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var sourceLogicalNamesArray, result, entities;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sourceLogicalNamesArray = Array.isArray(sourceLogicalNames) ? sourceLogicalNames : [sourceLogicalNames];
                        return [4 /*yield*/, this.cache.getMultiple("".concat(CachedMetadataStoreServicePrefix, "-").concat(getEntityMetadataFromCRM), sourceLogicalNamesArray, function (keys) { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.metadataStoreService.getEntityMetadataFromCRM(keys)];
                                        case 1:
                                            data = _a.sent();
                                            return [2 /*return*/, keys.map(function (x) { return data[x]; })];
                                    }
                                });
                            }); }, allowStaleData)];
                    case 1:
                        result = _a.sent();
                        entities = result
                            .filter(function (e) { return e; })
                            .reduce(function (a, e) {
                            var _a;
                            return (__assign(__assign({}, a), (_a = {}, _a[e.LogicalName] = e, _a)));
                        }, {});
                        return [2 /*return*/, entities];
                }
            });
        });
    };
    CachedMetadataStoreService.prototype.getBrandMetadataItems = function (useLazyRelatedEntitiesLoading, allowStaleData) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var metadataItemsPromise, getMetadataItems, key;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        metadataItemsPromise = null;
                        getMetadataItems = function () {
                            if (metadataItemsPromise == null) {
                                metadataItemsPromise = _this.metadataStoreService.getBrandMetadataItems(useLazyRelatedEntitiesLoading);
                            }
                            return metadataItemsPromise;
                        };
                        key = "".concat(CachedMetadataStoreServicePrefix, "-").concat(brandLogicalName);
                        return [4 /*yield*/, this.cache.get(key, function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, getMetadataItems()];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                });
                            }); }, allowStaleData)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CachedMetadataStoreService.prototype.getCjoEventsByName = function (eventNames, allowStaleData) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var eventNamesArray, result;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        eventNamesArray = Array.isArray(eventNames) ? eventNames : [eventNames];
                        return [4 /*yield*/, this.cache.getMultiple("".concat(CachedMetadataStoreServicePrefix, "-").concat(getCjoEventsByNamePrefix), eventNamesArray, function (keys) { return __awaiter(_this, void 0, void 0, function () {
                                var data, groupedData;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.metadataStoreService.getCjoEventsByName(keys)];
                                        case 1:
                                            data = (_a.sent()).value;
                                            groupedData = keys.map(function (key) { return data.filter(function (d) { return d.sourceLogicalName === key; }); });
                                            return [2 /*return*/, groupedData];
                                    }
                                });
                            }); }, allowStaleData)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, {
                                value: result.flatMap(function (item) { return item; })
                            }];
                }
            });
        });
    };
    CachedMetadataStoreService.prototype.getAllCjoEventsNames = function (inludeDraftEvents, businessUnit, allowStaleData, isDoubleOptIn) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var key, result, _a, resolvedBusinessUnit_1, items;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        key = "".concat(CachedMetadataStoreServicePrefix, "-").concat(getAllCjoEventsNamesPrefix, "_").concat(!!inludeDraftEvents, "_").concat(!!isDoubleOptIn);
                        return [4 /*yield*/, this.cache.get(key, function () { return _this.metadataStoreService.getAllCjoEventsNames(inludeDraftEvents, businessUnit, allowStaleData, isDoubleOptIn); })];
                    case 1:
                        result = _b.sent();
                        _a = false;
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, isBusinessUnitScopingEnabled()];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        if (!_a) return [3 /*break*/, 5];
                        return [4 /*yield*/, resolveBusinessUnit(businessUnit)];
                    case 4:
                        resolvedBusinessUnit_1 = _b.sent();
                        items = result.value.filter(function (value) {
                            return value.businessUnit && value.businessUnit === resolvedBusinessUnit_1;
                        });
                        result.value = items;
                        _b.label = 5;
                    case 5: return [2 /*return*/, result];
                }
            });
        });
    };
    CachedMetadataStoreService.prototype.preloadData = function (request) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var product, dataverse, dataverseEntities, tasks, _b, events, brand, legal, specificBinding;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        product = request.product, dataverse = request.dataverse;
                        dataverseEntities = new Set();
                        tasks = [];
                        if (dataverse) {
                            dataverse.audience.forEach(function (a) { return dataverseEntities.add(a); });
                            if (dataverseEntities.size > 0) {
                                tasks.push(this.getDataverseTableMetadataItems(Array.from(dataverseEntities)));
                            }
                        }
                        if (product === "CJO") {
                            _b = request, events = _b.events, brand = _b.brand, legal = _b.legal, specificBinding = _b.specificBinding;
                            if (brand === null || brand === void 0 ? void 0 : brand.includeBrand) {
                                tasks.push(this.getBrandMetadataItems(true));
                            }
                            if (legal === null || legal === void 0 ? void 0 : legal.includeLegal) {
                                tasks.push(this.getAllLegalMetadataItems());
                            }
                            if (events) {
                                if (events.loadEventDisplayNames) {
                                    tasks.push(this.getAllCjoEventsNames(events.includeDraftEvents));
                                }
                                if (((_a = events.specificEvents) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                    tasks.push(this.getAllCjoEventsMetadataItems(events.specificEvents));
                                }
                            }
                            if (specificBinding === null || specificBinding === void 0 ? void 0 : specificBinding.includeSpecificBinding) {
                                tasks.push(this.getDatasetConfiguration());
                            }
                        }
                        return [4 /*yield*/, Promise.all(tasks)];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CachedMetadataStoreService.prototype.getDateFormats = function (request, allowStaleData) {
        if (allowStaleData === void 0) { allowStaleData = false; }
        return __awaiter(this, void 0, void 0, function () {
            var key;
            var _this = this;
            return __generator(this, function (_a) {
                key = "".concat(CachedMetadataStoreServicePrefix, "-").concat(getDateFormatsPrefix, "_").concat(request.timeZoneId, "_").concat(request.cultureName, "_").concat(!!request.dateOnly, "_").concat(!!request.timezoneIndepentent);
                return [2 /*return*/, this.cache.get(key, function () { return _this.metadataStoreService.getDateFormats(request); }, allowStaleData)];
            });
        });
    };
    CachedMetadataStoreService.prototype.getConversationVariables = function (request, allowStaleData) {
        return __awaiter(this, void 0, void 0, function () {
            var key;
            var _this = this;
            return __generator(this, function (_a) {
                key = "".concat(CachedMetadataStoreServicePrefix, "-getConversationVariables_").concat(request.proactiveEngagementConfigId);
                return [2 /*return*/, this.cache.get(key, function () { return _this.metadataStoreService.getConversationVariables(request); }, allowStaleData)];
            });
        });
    };
    CachedMetadataStoreService.staticBindingEntitiesKey = "staticBindingEntities";
    return CachedMetadataStoreService;
}(Service));
export { CachedMetadataStoreService };
